<template>
  <div class="plug-list">
    <el-breadcrumb separator="/" class="loactions">
      <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>插件库</el-breadcrumb-item>
    </el-breadcrumb>
    <ListTabs v-for="recommend in plug_list" :items="recommend"/>
  </div>
</template>

<script>
import ListTabs from '@/components/common/list-tabs';
import { get_plug_list } from '@/utils/common';

export default {
  name: 'plug_list',
  components: {
    ListTabs
  },
  data() {
    return {
      plug_list: [],
      page: 1
    };
  },
  mounted() {
    this.plug_items();
  },
  methods: {
    plug_items() {
      get_plug_list(this);
    }
  }

};
</script>

<style lang="less">

</style>
