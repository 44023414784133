<template>
<!--  <el-card class="download" v-if="out_url">-->
    <el-alert type="error" :closable="false" title="" center class="download" v-if="out_url">
      <div class="urls">
        页面即将跳转至：{{ url }}
<!--        <el-input v-model="url" readonly class="urls"></el-input>-->
      </div>

      <el-button round size="small" type="danger" plain @click="got_pages">跳转</el-button>
    </el-alert>
<!--  </el-card>-->
</template>

<script>
export default {
  name: 'download',
  data() {
    return {
      out_url: false,
      url: ''
    };
  },
  mounted() {
    let query = this.$route.query;
    if (query.url) {
      this.out_url = true;
      this.url = query.url;
    }
  },
  methods: {
    got_pages() {
      location.href = this.url;
    }
  }
};
</script>

<style lang="less">
.download {
  width: 78%;
  margin: 45px auto;

  .urls {
    margin: 15px auto;
  }
}
</style>
