<template>
  <div class="notice-details-box">
    <el-breadcrumb separator="/" class="loactions">
      <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'notice' }">笔记</el-breadcrumb-item>
      <el-breadcrumb-item>{{ details.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="details-header">
        <div>
          <div class="details-name">{{ details.name }}</div>
          <div class="details-author"><i class="el-icon-user"></i>{{ details.author }}
            {{ details.timer }}
          </div>
        </div>
      </div>

    </el-card>
    <el-alert
      type="success"
      :closable="false" class="jingle">
      {{ details.jingle }}
    </el-alert>
    <el-card class="details-content">
      <div v-html="details.content"></div>
    </el-card>
  </div>
</template>
<script>
import { get_article_details } from '@/utils/common';

export default {
  name: 'notice_details',
  data() {
    return {
      details: [],
      id: this.$route.params.id
    };
  },
  watch: {
    $route(to, form) {
      this.id = to.params.id;
      get_article_details(this);
    }
  },
  mounted() {
    get_article_details(this);
  }
};
</script>

<style lang="less">
.notice-details-box {
  .details-header {
    .details-name, .details-version {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .details-author, .details-dowload {
      color: #b9b9b9;
      font-size: 14px;
    }
  }

  .jingle {
    margin-top: 15px;
    border-left: 5px solid #67C23A;
  }

  .details-content {
    margin-top: 15px !important;

    img {
      max-width: 100%;
    }
  }
}
</style>
