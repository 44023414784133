<template>
  <div class="plug-details">
    <el-breadcrumb separator="/" class="loactions">
      <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'plug' }">插件库</el-breadcrumb-item>
      <el-breadcrumb-item>{{ details.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="details-header">
        <div>
          <div class="details-name">{{ details.name }}</div>
          <div class="details-author"><i class="el-icon-user"></i>{{ details.author }}
            {{ details.create }}
          </div>
        </div>
        <div>
          <div class="details-version">V{{ details.version }}</div>
          <div class="details-dowload"><i class="el-icon-download"></i>{{ details.down }}</div>
        </div>
      </div>

    </el-card>
    <el-card class="details-content">
      <div v-html="details.items"></div>
    </el-card>
  </div>
</template>

<script>
import { get_plug_details } from '@/utils/common';

export default {
  name: 'plug_details',
  data() {
    return {
      details: [],
      id: this.$route.params.id
    };
  },
  watch: {
    $route(to, form) {
      this.id = to.params.id;
      get_plug_details(self);
    }
  },
  mounted() {
    get_plug_details(this);
  }
};
</script>

<style lang="less">
.plug-details {
  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:last-child {
      text-align: right;
    }

    .details-name, .details-version {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .details-author, .details-dowload {
      color: #b9b9b9;
      font-size: 14px;
    }
  }

  .details-content {
    margin-top: 15px!important;
  }
}
</style>
