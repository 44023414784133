<template>
  <el-card class="list-card">
    <div slot="header" class="index-tabs">
      <div>{{ items.name }}</div>
      <div class="more" v-if="items.date">{{ items.date }}</div>
    </div>
    <div class="list-card-content">
      <div class="list-image">
        <el-image :src="items.image">
          <el-image slot="error" src="/static/images/error.png"></el-image>
        </el-image>
      </div>
      <div class="list-items">
        <div class="list-tag">
          <el-tag size="small" v-for="tags in tag_list" class="tag-info">{{ tags }}</el-tag>
        </div>
        <div class="list-jingle">
          {{ items.jingle }}
        </div>
        <div class="list-btn">
          <el-button type="primary" size="small" :icon="its.icon" plain round
                     v-for="(its,inx) in items.btn" :key="inx" @click="btn_click(inx)"
                     :ref="'btn'+inx"
                     :links="its">
            {{ its.name }}
          </el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'list-tabs',
  props: ['items'],
  computed: {
    tag_list() {
      let tags = this.items.tag;
      if (tags.length < 1) {
        return [];
      }

      return tags.split(' ');
    }
  },
  methods: {
    btn_click(inx) {
      let items = this.$refs['btn' + inx][0];
      // console.log(items)
      let values = items.$attrs.links;
      let name = 'download';
      let query = {
        url: values.link
      };
      let params = {};
      if (typeof values.link == 'object') {
        name = values.link.name;
        query = values.link.query;
        params = values.link.params || {};
      }
      if (values.target == '_blank') {
        let { href } = this.$router.resolve({
          name,
          query,
          params
        });
        window.open(href, '_blank', console.log(href));
        return;
      }
      this.$router.push({
        name,
        query,
        params
      });
    }
  }
};
</script>

<style lang="less">
.list-card {
  margin-bottom: 10px;

  .index-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more {
      color: #e03f0c;
    }
  }

  .list-card-content {
    display: flex;

    .list-image {
      width: 120px;
      margin-right: 10px;

      img {
        width: 100%;
      }
    }

    .list-items {
      width: calc(100% - 130px);
      line-height: 25px;
      font-size: 14px;
      box-sizing: border-box;
      padding: 0 10px;
      border-left: 1px solid #D9D9D9;
      cursor: pointer;
      position: relative;

      .list-tag {
        margin-bottom: 5px;

        .tag-info {
          margin-right: 5px;
        }
      }

      .list-jingle {
        color: #8D8D8D;
      }

      .list-btn {
        text-align: right;
        margin-top: 10px;
      }
    }
  }
}
</style>
