<template>
  <div class="notice-box">
    <el-breadcrumb separator="/" class="loactions">
      <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>笔记</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="notice-list">
      <el-card class="notice-items" v-for="notice in notice_list">
        <div slot="header" class="index-tabs">
          <div>
            <el-tag v-if="notice.top_state==1" size="small" type="danger">置顶</el-tag>
            {{ notice.name }}
          </div>
          <div class="more">{{ notice.author }} / {{ notice.timer }}</div>
        </div>
        <div class="notice-content">
          <div class="jingle">
            {{ notice.jingle }}
            <el-button type="text" @click="go_details(notice.id)">查看全文</el-button>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { get_article_list } from '@/utils/common';

export default {
  name: 'notice',
  data() {
    return {
      notice_list: [],
      page: 1
    };
  },
  mounted() {
    get_article_list(this);
  },
  methods: {
    go_details(id) {
      this.$router.push({
        name: 'notice_details',
        params: {
          id
        }
      });
    }
  }
};
</script>

<style lang="less">
.notice-box {
  .notice-items {
    margin-bottom: 15px;

    .index-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .more {
        color: #D9D9D9;
        font-size: 12px;
      }
    }

    .jingle {
      line-height: 25px;
      font-size: 12px;
    }
  }
}
</style>
