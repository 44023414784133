<template>
  <div class="recommend-box">
    <el-breadcrumb separator="/" class="loactions">
      <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>开发工具</el-breadcrumb-item>
    </el-breadcrumb>
    <ListTabs v-for="recommend in recommend_list" :items="recommend"/>
  </div>
</template>

<script>
import ListTabs from '@/components/common/list-tabs';
import { RECOMMEND } from '@/utils/constant';

export default {
  name: 'recommend',
  components: {
    ListTabs
  },
  data() {
    return {
      recommend_list: RECOMMEND
    };
  }
};
</script>

<style lang="less">
.recommend-box {

}
</style>
